const ArticleCrumbs = [
  {
    id: 1,
    title: "Home",
    slug: "/",
  },
  {
    id: 2,
    title: "Latest News",
    slug: "/articles",
  },
]

export default ArticleCrumbs
