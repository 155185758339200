import React from "react"
import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import {
  Hero,
  FeaturedBlogs,
  LatestNews,
  FeaturedArticles,
} from "../containers"
import { BreadCrumb, TitleV2 } from "../components"
import ArticleCrumbs from "../constants/BreadCrumbs/ArticleCrumbs"

const ArticlePage = ({ data }) => {
  const {
    allStrapiArticle: { nodes: articles },
    allStrapiBlog: { nodes: blogs },
  } = data

  const image = getImage(
    data.strapiNewsPage.image.localFile.childImageSharp.gatsbyImageData
  )

  return (
    <>
      <Hero image={image} title={data.strapiNewsPage.title} />
      <BreadCrumb crumbs={ArticleCrumbs} />
      <div className="uk-margin-small-top">
        <TitleV2 title={data.strapiNewsPage.title} styleClass="underline" />
      </div>
      <FeaturedBlogs blogs={blogs} />
      <LatestNews />
      <FeaturedArticles
        articles={articles}
        title="Featured Articles"
        styleClass="underlineOrange"
      />
    </>
  )
}
export default ArticlePage

export const query = graphql`
  {
    strapiNewsPage {
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(formats: AUTO, placeholder: BLURRED)
          }
        }
      }
      title
    }
    allStrapiBlog {
      nodes {
        id
        label
        title
        published_at
        date
        shortDescription
        slug
        link
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                aspectRatio: 3.4
                height: 300
                layout: CONSTRAINED
                placeholder: BLURRED
              )
            }
          }
        }
      }
    }
    allStrapiArticle(filter: { featured: { eq: true } }) {
      nodes {
        strapiId
        slug
        title
        description
        date
        category {
          name
        }
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 800
                height: 500
                placeholder: BLURRED
                formats: [AUTO]
              )
            }
          }
        }
        author {
          name
          picture {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 30
                  height: 30
                  placeholder: BLURRED
                  formats: [AUTO]
                )
              }
            }
          }
        }
      }
    }
  }
`
